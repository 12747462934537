import { ReactComponent as ReactLogoConroo } from '../assets/images/conroo-logo.svg';
import { ReactComponent as ReactLogoImpala } from '../assets/images/impala-logo.svg';
import { ReactComponent as ReactLogoPassify } from '../assets/images/passify-logo.svg';
import truckerCard from "../assets/images/Truckercard-kreis.png";

export enum IdpTypeEnum {
  IMPALA = "IMPALA",
  CONROO = "CONROO",
  PASSIFY = "PASSIFY",
  TRUCKERCARD = "TRUCKERCARD"
};

export const IdpTypeLogo = (props: { type: IdpTypeEnum } ) => {
  if(props.type === IdpTypeEnum.IMPALA) {
    return <div title="Impala-ID" ><ReactLogoImpala height={'24px'} width={'24px'} /></div>;
  }
  if(props.type === IdpTypeEnum.CONROO) {
    return <div title="Conroo App"><ReactLogoConroo height={'24px'} width={'24px'} /></div>;
  }
  if(props.type === IdpTypeEnum.PASSIFY) {
    return <div title="Passify App"><ReactLogoPassify height={'24px'} width={'24px'} /></div>;
  }
  if(props.type === IdpTypeEnum.TRUCKERCARD) {
    return <div style={{ height: "100%", alignContent: 'center' }} title="Trucker Card"><img src={truckerCard} alt="TruckerCard"  width={'24px'} /></div>;
  }
  return null;
}
