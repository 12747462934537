import { t } from 'i18next';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate, useParams
} from "react-router-dom";
import { useErrorStatus } from '../../contexts/errorstatus.context';
import { useIdp } from '../../contexts/idp.context';
import { useSession } from '../../contexts/session.context';
import { ViewNameEnum } from '../../dto/ViewNameEnum';
import { sessionService } from '../../services/session.service';

export const IdpLogin = () => {
  const { userManager, providerName, setProviderCode } = useIdp();
  const { oidcProviderCode } = useParams();
  const { setErrorStatusText } = useErrorStatus();

  const navigate = useNavigate();
  const basePath = process.env.PUBLIC_URL || "";

  // console.log("UserData=", JSON.stringify(userData), !!userManager, "session=", !!session, "isloading:", isLoading, "isLoggingIn: ", isLoggingIn);

  useEffect(() => {
    setProviderCode(oidcProviderCode);
  }, [oidcProviderCode, setProviderCode])

  useEffect(() => {
    // console.log("Do login");
    const redirectPath = window.location.protocol + '//' + window.location.hostname + (window.location.port && (':' + window.location.port)) + basePath + ViewNameEnum.IDPLOGINCALLBACK + "/" + oidcProviderCode;
    userManager?.signinRedirect({ redirect_uri: redirectPath })
      .catch(e => {
        setErrorStatusText("Error: " + e);
        navigate(ViewNameEnum.ROOT);
      });
  }
    , [basePath, navigate, oidcProviderCode, setErrorStatusText, userManager])

  return (<LoggingIn providerName={providerName} />);
};

export const IdpLoginInternal = () => {
  const { userManager, providerName, setProviderCode, signOutRedirect, signOutSilent } = useIdp();
  const { oidcProviderCode } = useParams();
  const { setSession, locale } = useSession();
  const [isLoggingIn, setLoggingIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setErrorStatusText } = useErrorStatus();

  const basePath = process.env.PUBLIC_URL || "";
  const currentPath = window.location.protocol + '//' + window.location.hostname + (window.location.port && (':' + window.location.port)) + basePath + location.pathname + location.search;

  useEffect(() => {
    setProviderCode(oidcProviderCode);
  }, [oidcProviderCode, setProviderCode])

  useEffect(() => {
    if (userManager && !isLoggingIn && locale) {
      setLoggingIn(true);
      userManager?.signinRedirectCallback(currentPath)
        .then(() => userManager?.getUser())
        .then(u => {
          if (!u) {
            throw new Error('No OIDC user session');
          }
          return u;
        })
        .then((u) => {
          sessionService.setIdpRefreshtoken(u.refresh_token);
          return sessionService.tokenLogin( u.id_token || u.access_token, locale)
            .catch((e) => {
              sessionService.setIdpRefreshtoken(undefined)
              return signOutSilent().then(() => { throw e; });
            })
        }
        )
        .then((loginResponse) => setSession(loginResponse.data?.session))
        .catch(e => { console.error("Error: " + e); setErrorStatusText("Error: " + e); }
        )
        .finally(() => navigate(ViewNameEnum.ROOT));
    }
  }, [currentPath, isLoggingIn, locale, navigate, setErrorStatusText, setSession, signOutRedirect, signOutSilent, userManager])

  return (<LoggingIn providerName={providerName} />);
}

export const LoggingIn = (props: { providerName?: string }) => (
  <div className="login-background">
    <div className="login-card-view">
      <i className="pi pi-spin pi-spinner" /><div>{t('loginWith', { name: props.providerName })}</div><MainButton />
    </div>
  </div>
);

export const IdpLogout = () => {

  const { logout } = useSession();
  const { signOutRedirect, providerName } = useIdp();
  const [isSigningOut, setSigningOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSigningOut) {
      setSigningOut(true);

      // console.log("id_token:", userData?.id_token);

      Promise.resolve()
        .then(logout)
        .then(() => sessionService.setIdpRefreshtoken(undefined))
        .then(() => signOutRedirect())
        .finally(() => navigate(ViewNameEnum.ROOT))
    }
  }
    , [isSigningOut, logout, navigate, signOutRedirect]);

  return (
    <div className="login-background">
      <div className="login-card-view">
        {<><i className="pi pi-spin pi-spinner" /><div>{t('logout') + " " + providerName}</div><MainButton /></>}
      </div>
    </div>
  );
}

const MainButton = () => {
  const { t } = useTranslation();
  return (
    <div>
      <span className="errorview-Link">
        <div className="input-button-row">
          <Button label={t('errorview.button')} className="main-button" onClick={() => window.location.replace(window.location.origin)} />
        </div>
      </span>
    </div>);
}